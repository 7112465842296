<template>
    <div class="form-group-title">
        <h3>{{ label }}</h3>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'FormGroupTitle',

    props: {
        label: {
            type: String,
            default: "设置"
        }
    },

    data () {
        return {}
    },
    computed: {
    },
    methods: {},
    created () {},
    beforeMount () {},
    mounted () {},
    components: {},
    watch: {}
}
</script>

<style lang='scss' scoped>
    .form-group-title{
        display: flex;
        align-items: center;
        border-bottom: 1px solid #EEEEEE;
        margin-bottom: 24px;

        &:nth-child(n+2) {
            margin-top: 24px;
        }

        h3 {
            line-height: 55px;
            font-size: 14px;
            font-weight: bold;
        }
    }
</style>